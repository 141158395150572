<script setup lang="ts">
    import { computed, ref } from 'vue';
    import ShippingAddressSelection from '@/checkout/components/ShippingAddressSelection.vue';
    import { ShippingAddressOption } from '@/types/ShippingAddressOption';
    import { type AddressDto, DeliveryTime } from '@containex/portal-backend-dto';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useCompanyQuery } from '@/composables/company';
    import { areAddressesEqual, isEnteredAddressEmpty, isMedusaAddressValid } from '@/util/addressHelpers';
    import { medusaAddressToAddressDto } from '@containex/portal-medusa-mapper';
    import { useCheckoutAction, useCheckoutQuery } from '@/checkout/composables/checkout';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import {
        useCheckoutDeliveryOptionAction,
        useCheckoutDeliveryOptionQuery,
    } from '@/checkout/composables/checkout-delivery-option';
    import LineItemGroupEdit from '@/checkout/components/sale/LineItemGroupEdit.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
    import BlockUI from 'primevue/blockui';
    import { useMarketAction } from '@/composables/market';
    import { useI18n } from 'vue-i18n';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';

    const { t } = useI18n();
    const checkoutAction = useCheckoutAction();
    const companyQuery = useCompanyQuery();
    const { cart, currentZipCode } = useCartQuery();
    const cartAction = useCartAction();
    const checkoutDeliveryOptionAction = useCheckoutDeliveryOptionAction();
    const checkoutDeliveryOptionQuery = useCheckoutDeliveryOptionQuery();
    const marketAction = useMarketAction();
    const { transportCosts } = useCheckoutQuery();

    const defaultShippingAddress = companyQuery.company.value?.deliveryAddress;
    const cartShippingAddress = cart.value?.shipping_address;
    const cartId = cart.value?.id ?? '';
    const shippingAddressOption = ref(ShippingAddressOption.DEFAULT);
    const differentShippingAddress = ref<AddressDto>({});

    const joinedData = computed(() => {
        const lineItems: LineItem[] = cart.value?.items ?? [];
        const lineItemGroups = cart.value?.lineItemGroups ?? [];
        const sortedGroups = lineItemGroups.slice(0).sort((a, b) => a.id.localeCompare(b.id));

        return sortedGroups.flatMap((group) => {
            const items = lineItems.filter((item) => item.line_item_group_id === group.id);
            const transportOption = transportCosts.value.find(
                (transportCost) => transportCost.lineItemGroupId === group.id
            );

            if (items.length === 0 || transportOption == null) {
                return [];
            }

            return {
                items,
                group,
                option: checkoutDeliveryOptionQuery.forLineItemGroupId(group.id).value,
                transportOption,
            };
        });
    });

    const transportCostTask = useAsyncTask(async () => {
        await checkoutAction.calculateTransportCost(cartId);
        await cartAction.initCartStore();
    }).restartable();

    const initTask = useAsyncTask(async () => {
        // determine selected address and address option
        if (cartShippingAddress == null || !isMedusaAddressValid(cartShippingAddress)) {
            differentShippingAddress.value.postalCode = currentZipCode.value;

            if (currentZipCode.value === defaultShippingAddress?.postalCode) {
                await selectDefaultAddress();
            } else {
                shippingAddressOption.value = ShippingAddressOption.CUSTOM;
            }
        } else if (
            defaultShippingAddress != null &&
            isMedusaAddressValid(cartShippingAddress) &&
            areAddressesEqual(medusaAddressToAddressDto(cartShippingAddress), defaultShippingAddress)
        ) {
            shippingAddressOption.value = ShippingAddressOption.DEFAULT;
            differentShippingAddress.value.postalCode = currentZipCode.value;
        } else {
            shippingAddressOption.value = ShippingAddressOption.CUSTOM;
            differentShippingAddress.value = medusaAddressToAddressDto(cartShippingAddress);
        }

        await transportCostTask.perform();
    });

    const updateShippingAddressTask = useAsyncTask(async (signal, updatedAddress: AddressDto) => {
        if (!isEnteredAddressEmpty(updatedAddress) && updatedAddress.postalCode != null) {
            const regionId = await marketAction.getRegionByZipCode(updatedAddress.postalCode);
            await checkoutAction.updateCartShippingAddress(updatedAddress, regionId);
        } else {
            await cartAction.updateRegionBasedOnZipCodeAndRentalDuration(
                updatedAddress.postalCode ?? '',
                undefined,
                undefined
            );
        }
    });

    const updateGroupTask = useAsyncTask(async (signal, group: LineItemGroup) => {
        await checkoutAction.updateLineItemGroup(
            {
                id: group.id,
                deliveryTime: group.delivery_time,
                transportType: group.transport_type,
                estimatedDeliveryDateStart: group.estimated_delivery_date_start,
                estimatedDeliveryDateEnd: group.estimated_delivery_date_end,
            },
            group.transport_price
        );
    });

    void initTask.perform();

    async function selectDefaultAddress(): Promise<void> {
        shippingAddressOption.value = ShippingAddressOption.DEFAULT;

        if (defaultShippingAddress != null) {
            await updateShippingAddressTask.perform(defaultShippingAddress);
            await transportCostTask.perform();
        }
    }

    async function selectDifferentAddress(address: AddressDto): Promise<void> {
        shippingAddressOption.value = ShippingAddressOption.CUSTOM;
        differentShippingAddress.value = address;

        await updateShippingAddressTask.perform(address);
        await transportCostTask.perform();
    }

    async function updateGroupOption(group: LineItemGroup, option: CheckoutDeliveryOption): Promise<void> {
        const shouldReset = shouldResetDatesToInitial(option);

        checkoutDeliveryOptionAction.updateForLineItemGroup(group.id, option);

        await updateGroup({
            ...group,
            delivery_time: getDeliveryTimeForCheckoutDeliveryOption(option),
            estimated_delivery_date_start: shouldReset ? undefined : group.estimated_delivery_date_start,
            estimated_delivery_date_end: shouldReset ? undefined : group.estimated_delivery_date_end,
        });
    }

    function getDeliveryTimeForCheckoutDeliveryOption(option: CheckoutDeliveryOption): DeliveryTime {
        switch (option) {
            case CheckoutDeliveryOption.AsSoonAsPossible:
                return DeliveryTime.Asap;
            case CheckoutDeliveryOption.Custom:
                return DeliveryTime.Custom;
        }
    }

    async function updateGroup(group: LineItemGroup): Promise<void> {
        await updateGroupTask.perform(group);
    }

    function shouldResetDatesToInitial(option: CheckoutDeliveryOption): boolean {
        switch (option) {
            case CheckoutDeliveryOption.Custom:
                return false;
            case CheckoutDeliveryOption.AsSoonAsPossible:
                return true;
        }
    }
</script>

<template>
    <div v-if="initTask.isIdle" class="step-wrapper">
        <ShippingAddressSelection
            v-if="defaultShippingAddress != null"
            :shipping-address-option="shippingAddressOption"
            :default-shipping-address="defaultShippingAddress"
            :different-shipping-address="differentShippingAddress"
            :blocked="updateShippingAddressTask.isRunning"
            :is-rental="false"
            @select-default-address="selectDefaultAddress"
            @select-different-address="selectDifferentAddress"
        />
        <BlockUI :blocked="transportCostTask.isRunning || updateGroupTask.isRunning">
            <CheckoutSectionCard>
                <template #header>{{ t('CART.STEPS.DELIVERY.TITLE') }}</template>
                <template #content>
                    <span>{{ t('CART.STEPS.DELIVERY.DESCRIPTION') }}</span>
                    <LineItemGroupEdit
                        v-for="data of joinedData"
                        :key="data.group.id"
                        :items="data.items"
                        :group="data.group"
                        :option="data.option"
                        :transport-cost="data.transportOption"
                        @update:option="updateGroupOption(data.group, $event)"
                        @update:group="updateGroup"
                        @update-group-and-option="updateGroupOption"
                    />
                </template>
            </CheckoutSectionCard>
        </BlockUI>
    </div>
    <LoadingSpinner v-else-if="initTask.isRunning" class="loading-spinner-positioning" />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .step-wrapper {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .panel-content {
        display: flex;
        gap: main.$spacing-5;
    }

    .line-items {
        flex: 1;
    }

    .delivery-date {
        color: main.$color-green-dark;
        font-size: main.$font-size-2;
    }

    .loading-spinner-positioning {
        width: 100%;
    }
</style>
