<script setup lang="ts">
    import { computed } from 'vue';
    import Button from 'primevue/button';
    import ButtonGroup from 'primevue/buttongroup';
    import Panel from 'primevue/panel';
    import type { LineItem, LineItemGroup } from '@containex/portal-backend-api-client';
    import { CheckoutDeliveryOption } from '@/checkout/model/checkout-delivery-option';
    import { differenceInWeeks, parseISO } from 'date-fns';
    import { useDateFormatter } from '@/composables/date-format';
    import LineItemDisplay from '@/checkout/components/LineItemDisplay.vue';
    import WeekPicker from '@/components/WeekPicker.vue';
    import {
        DeliveryTime,
        DepotType,
        type TransportCostDto,
        TransportType,
        type TransportTypeCostDto,
    } from '@containex/portal-backend-dto';
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import { useI18n } from 'vue-i18n';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';
    import DeliveryCost from '@/checkout/components/common/DeliveryCost.vue';
    import { sleep } from '@/util/sleep';
    import { useAsyncTask } from 'vue-concurrency';
    import { lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts } from '@/util/line-items';
    import { componentStyle } from '@/styling/util/component-style';

    const props = defineProps<{
        group: LineItemGroup;
        items: LineItem[];
        transportCost: TransportCostDto;
        option: CheckoutDeliveryOption;
    }>();

    const emits = defineEmits<{
        'update:option': [CheckoutDeliveryOption];
        'update:group': [LineItemGroup];
        'update-group-and-option': [LineItemGroup, CheckoutDeliveryOption];
    }>();

    const { t } = useI18n();
    const { relativeDateFormatter, dateFormatter } = useDateFormatter();

    const title = computed(() => props.group.depot.address.city ?? t('CHECKOUT.SUMMARY.UNKNOWN_ADDRESS'));

    const isProductionSite = computed(() => props.group.depot.type === DepotType.ProductionSite);

    const craneTruckOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.CraneTruck
        )
    );
    const pickupOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.PickUp
        )
    );
    const truckOption = computed(() =>
        props.transportCost.transportTypeCosts?.find(
            (option: TransportTypeCostDto) => option.transportType === TransportType.Truck
        )
    );
    const isDeliveryTimeAsap = computed(() => props.group.delivery_time === DeliveryTime.Asap);

    const dateRange = computed<[Date | undefined, Date | undefined]>(() => {
        const start = props.group.estimated_delivery_date_start;
        const end = props.group.estimated_delivery_date_end;

        const parsedStart = start != null ? parseISO(start) : start;
        const parsedEnd = end != null ? parseISO(end) : end;

        return [parsedStart ?? undefined, parsedEnd ?? undefined];
    });

    const earliestPossibleDeliveryDate = computed(() => {
        const date = props.group.initial_earliest_delivery_date;

        return date != null ? parseISO(date) : undefined;
    });
    const earliestPossibleDeliveryDateDisplay = computed(() => {
        if (earliestPossibleDeliveryDate.value == null) {
            return t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN');
        }

        const difference = differenceInWeeks(earliestPossibleDeliveryDate.value, Date.now());

        return relativeDateFormatter.formatWeeks(difference).value;
    });

    const filteredLineItems = computed(() =>
        lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts(props.items)
    );

    // this method gets called multiple times on changes, thus the hack
    // with sleep and useAyncTask to reduce its invocations
    const setTransportType = useAsyncTask(async (signal, method: TransportType) => {
        await sleep(0);

        const transportOption = getTransportOptionForMethod(method);
        const customGroup: LineItemGroup = {
            ...props.group,
            transport_type: method,
            transport_price: transportOption?.price ?? 0,
        };

        if (method === TransportType.PickUp) {
            emits('update-group-and-option', customGroup, CheckoutDeliveryOption.Custom);
        } else {
            emits('update:group', customGroup);
        }
    }).drop();

    function getTransportOptionForMethod(method: TransportType): TransportTypeCostDto | undefined {
        switch (method) {
            case TransportType.CraneTruck:
                return craneTruckOption.value;
            case TransportType.Truck:
                return truckOption.value;
            case TransportType.PickUp:
                return pickupOption.value;
        }
    }

    function setDateRange([start, end]: [Date | undefined, Date | undefined]): void {
        emits('update:group', {
            ...props.group,
            estimated_delivery_date_start: start != null ? dateFormatter.formatForZod(start) : undefined,
            estimated_delivery_date_end: end != null ? dateFormatter.formatForZod(end) : undefined,
        });
    }

    const panelStyle = componentStyle({
        borderColor: '{ surface.200 }',
    });
</script>

<template>
    <Panel toggleable :dt="panelStyle">
        <template #header>
            <span v-if="!isProductionSite" class="bold-panel-title">{{
                t('CHECKOUT.SUMMARY.DEPOT', { depot: title })
            }}</span>
            <span v-else class="bold-panel-title">{{ t('CHECKOUT.SUMMARY.FROM_FACTORY') }}</span>
        </template>
        <div class="panel-content">
            <div class="line-items">
                <LineItemDisplay
                    v-for="lineItem of filteredLineItems"
                    :key="lineItem.id"
                    :line-item="lineItem"
                ></LineItemDisplay>
            </div>
            <div class="options">
                <div class="delivery-options">
                    <div v-if="craneTruckOption" class="delivery-option-wrapper">
                        <RadioCard
                            name="transportType"
                            :model-value="group.transport_type"
                            :value="TransportType.CraneTruck"
                            @update:model-value="setTransportType.perform(TransportType.CraneTruck)"
                        >
                            <template #header>
                                <div class="radio-card-header">
                                    <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.TITLE') }}</span>
                                    <DeliveryCost :price="craneTruckOption.price" class="delivery-cost" />
                                    <IconButtonWithOverlayPanel class="info-icon">
                                        <template #icon>
                                            <i class="pi pi-info-circle" />
                                        </template>
                                        <template #overlay-panel-content>
                                            <div>{{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.INFO') }}</div>
                                        </template>
                                    </IconButtonWithOverlayPanel>
                                </div>
                            </template>
                            <template v-if="group.transport_type === TransportType.CraneTruck" #content>
                                <p class="delivery-method-description">
                                    {{ t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.NOTICE') }}
                                </p>
                                <ButtonGroup class="button-group-delivery">
                                    <Button
                                        :class="{ 'button-delivery-option': !isDeliveryTimeAsap }"
                                        :label="t('CART.STEPS.DELIVERY.DELIVERY_ASAP')"
                                        :outlined="!isDeliveryTimeAsap"
                                        @click="emits('update:option', CheckoutDeliveryOption.AsSoonAsPossible)"
                                    />
                                    <Button
                                        :class="{ 'button-delivery-option': isDeliveryTimeAsap }"
                                        :label="t('CART.STEPS.DELIVERY.DELIVERY_CUSTOM')"
                                        :outlined="isDeliveryTimeAsap"
                                        @click="emits('update:option', CheckoutDeliveryOption.Custom)"
                                    />
                                </ButtonGroup>
                                <br />
                                <br />
                                <div v-if="isDeliveryTimeAsap">
                                    {{ t('CART.STEPS.DELIVERY.ESTIMATED_DELIVERY') }}:
                                    <br />
                                    <div class="delivery-date">
                                        {{ earliestPossibleDeliveryDateDisplay }}
                                    </div>
                                </div>
                                <div v-else>
                                    <WeekPicker
                                        class="week-picker-width"
                                        :range="dateRange"
                                        :min-date="earliestPossibleDeliveryDate"
                                        @update:range="setDateRange"
                                    />
                                </div>
                            </template>
                        </RadioCard>
                    </div>
                    <div v-if="truckOption" class="delivery-option-wrapper">
                        <RadioCard
                            name="transportType"
                            :model-value="group.transport_type"
                            :value="TransportType.Truck"
                            @update:model-value="setTransportType.perform(TransportType.Truck)"
                        >
                            <template #header>
                                <div class="radio-card-header">
                                    <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.TRUCK.TITLE') }}</span>
                                    <DeliveryCost :price="truckOption.price" class="delivery-cost" />
                                    <IconButtonWithOverlayPanel class="info-icon">
                                        <template #icon>
                                            <i class="pi pi-info-circle" />
                                        </template>
                                        <template #overlay-panel-content>
                                            <div>{{ t('CART.STEPS.DELIVERY.METHOD.TRUCK.INFO') }}</div>
                                        </template>
                                    </IconButtonWithOverlayPanel>
                                </div>
                            </template>
                            <template v-if="group.transport_type === TransportType.Truck" #content>
                                <p class="delivery-method-description">
                                    {{ t('CART.STEPS.DELIVERY.METHOD.TRUCK.NOTICE') }}
                                </p>
                                <ButtonGroup class="button-group-delivery">
                                    <Button
                                        :class="{ 'button-delivery-option': !isDeliveryTimeAsap }"
                                        :label="t('CART.STEPS.DELIVERY.DELIVERY_ASAP')"
                                        :outlined="!isDeliveryTimeAsap"
                                        @click="emits('update:option', CheckoutDeliveryOption.AsSoonAsPossible)"
                                    />
                                    <Button
                                        :class="{ 'button-delivery-option': isDeliveryTimeAsap }"
                                        :label="t('CART.STEPS.DELIVERY.DELIVERY_CUSTOM')"
                                        :outlined="isDeliveryTimeAsap"
                                        @click="emits('update:option', CheckoutDeliveryOption.Custom)"
                                    />
                                </ButtonGroup>
                                <br />
                                <br />
                                <div v-if="isDeliveryTimeAsap">
                                    {{ t('CART.STEPS.DELIVERY.ESTIMATED_DELIVERY') }}:
                                    <br />
                                    <div class="delivery-date">
                                        {{ earliestPossibleDeliveryDateDisplay }}
                                    </div>
                                </div>
                                <div v-else>
                                    <WeekPicker
                                        class="week-picker-width"
                                        :range="dateRange"
                                        :min-date="earliestPossibleDeliveryDate"
                                        @update:range="setDateRange"
                                    />
                                </div>
                            </template>
                        </RadioCard>
                    </div>
                    <div v-if="pickupOption" class="delivery-option-wrapper">
                        <RadioCard
                            name="transportType"
                            :model-value="group.transport_type"
                            :value="TransportType.PickUp"
                            @update:model-value="setTransportType.perform(TransportType.PickUp)"
                        >
                            <template #header>
                                <div class="radio-card-header">
                                    <span class="title">{{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.TITLE') }}</span>
                                    <DeliveryCost :price="pickupOption.price" />
                                    <IconButtonWithOverlayPanel class="info-icon">
                                        <template #icon>
                                            <i class="pi pi-info-circle" />
                                        </template>
                                        <template #overlay-panel-content>
                                            <div>{{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.INFO') }}</div>
                                        </template>
                                    </IconButtonWithOverlayPanel>
                                </div>
                            </template>
                            <template v-if="group.transport_type === TransportType.PickUp" #content>
                                <p class="delivery-method-description">
                                    {{ t('CART.STEPS.DELIVERY.METHOD.PICK_UP.NOTICE') }}
                                </p>
                                <WeekPicker
                                    class="week-picker-width"
                                    :range="dateRange"
                                    :min-date="earliestPossibleDeliveryDate"
                                    @update:range="setDateRange"
                                />
                            </template>
                        </RadioCard>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .panel-content {
        display: flex;
        gap: main.$spacing-5;
    }

    .delivery-method-description {
        margin-top: main.$spacing-3;
    }

    .delivery-option-wrapper {
        position: relative;
    }

    .delivery-cost {
        flex-shrink: 0;
    }

    .line-items {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-3;
    }

    .options {
        flex: 2;
    }

    .radio-card-header {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: main.$spacing-5;
        font-weight: 500;

        .title {
            flex-grow: 1;
            hyphens: auto;
        }
    }

    .button-delivery-option:hover {
        background-color: main.$color-background-bluegray;
    }

    .button-group-delivery {
        display: inline-flex;
        flex-wrap: nowrap;
        hyphens: auto;
    }

    .info-icon {
        color: main.$color-primary-500;

        &:hover {
            border: 0 none;
            border-radius: 50%;
            background-color: main.$color-background-bluegray;
        }
    }

    .delivery-date {
        color: main.$color-green-dark;
        font-size: main.$font-size-2;
    }

    .week-picker-width {
        width: auto;
    }

    .p-button {
        color: main.$toggle-button-active;
        background-color: main.$color-background-lightblue;
        border-color: main.$toggle-button-border;
        font-weight: 500;
    }

    .p-button-outlined {
        color: main.$color-text;
        background-color: main.$color-white;
        font-weight: 500;
    }

    .bold-panel-title {
        font-weight: bold;
    }

    @media (width <= 767px) {
        .panel-content {
            flex-direction: column;
        }

        .line-items,
        .options {
            width: 100%;
        }

        .options {
            margin-top: main.$spacing-3;
        }

        .p-button {
            padding-left: main.$spacing-3;
            padding-right: main.$spacing-3;
        }

        .delivery-cost {
            flex-shrink: 1;
        }
    }

    :deep(.radio-card-container) {
        font-weight: 400;
    }
</style>
