<script setup lang="ts">
    import DatePicker from 'primevue/datepicker';
    import { computed, useTemplateRef } from 'vue';
    import { workWeekOf } from '@containex/common-utils';
    import { isSameDay, nextMonday } from 'date-fns';

    const props = defineProps<{
        range: [Date | undefined, Date | undefined];
        minDate?: Date;
    }>();

    const emits = defineEmits<{
        'update:range': [[Date | undefined, Date | undefined]];
    }>();

    type DatePickerType = InstanceType<typeof DatePicker>;
    const datePickerRef = useTemplateRef<DatePickerType>('datePickerRef');

    const actualMinDate = computed(() => {
        if (props.minDate == null) {
            return undefined;
        }

        const [monday, friday] = workWeekOf(props.minDate);

        if (isSameDay(monday, props.minDate)) {
            return monday;
        }

        return nextMonday(friday);
    });
    const range = computed(() => {
        const start = props.range[0];
        const end = props.range[1];

        if (start == null && end == null) {
            return undefined;
        }

        return [start ?? null, end ?? null];
    });

    function updateRange(value: Date | Date[] | (Date | null)[] | undefined | null): void {
        if (!Array.isArray(value)) {
            return;
        }

        const start = value[0];

        const calculatedRange: [Date | undefined, Date | undefined] =
            start != null ? workWeekOf(start) : [undefined, undefined];

        // CPP-1053: we need to close the datePicker programatically
        // as it does not work with hide-on-range-selection="true"
        // as we don't set the 2nd value in the UI
        if (datePickerRef.value != null) {
            // @ts-expect-error this property does exist and we know it.
            datePickerRef.value.overlayVisible = false;
        }
        emits('update:range', calculatedRange);
    }
</script>

<template>
    <DatePicker
        ref="datePickerRef"
        :model-value="range"
        :manual-input="false"
        :show-icon="true"
        icon-display="input"
        show-week
        :min-date="actualMinDate"
        :disabled-days="[0, 6]"
        class="full-width"
        selection-mode="range"
        @update:model-value="updateRange"
    />
</template>

<style scoped lang="scss"></style>
